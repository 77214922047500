<template>
   <ObiText class="obi-pagination">
      <ObiText class="pagination-content">
         <ObiText inline v-html="$t('total_records_label', { total: meta.total })" />
         <ObiText inline v-html="$t('view_range_label', { pageFrom: meta.page_from, pageTo: meta.page_to })" />
      </ObiText>

      <ObiText as="ul" class="pagination">
         <ObiText as="li" class="page-item" :class="{ disabled: disablePreviousLink }">
            <ObiText as="a" class="page-link" @click.prevent="currentPage = currentPage - 1">
               <ObiText v-html="'&laquo;'" />
            </ObiText>
         </ObiText>
         <ObiText
            as="li"
            :key="index"
            class="page-item"
            v-for="(page, index) in pageRange"
            :class="{ active: currentPage === page }"
         >
            <ObiText as="a" v-html="page" class="page-link" @click.prevent="currentPage = page" />
         </ObiText>
         <ObiText as="li" class="page-item" :class="{ disabled: disableNextLink }">
            <ObiText as="a" class="page-link" @click.prevent="currentPage = currentPage + 1">
               <ObiText v-html="'&raquo;'" />
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { range } from 'lodash';

export default {
   name: 'ObiPagination',
   i18n: {
      messages: {
         tr: {
            total_records_label: 'Toplam <b>{total}</b> kayıt',
            view_range_label: '{pageFrom} - {pageTo} Arası gösteriliyor',
         },
         en: {
            total_records_label: 'Total <b>{total}</b> records',
            view_range_label: ' Showing {pageFrom} - {pageTo}',
         },
      },
   },
   props: {
      meta: {
         type: Object,
         required: true,
         default: () => ({
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: null,
            to: 1,
            total: 0,
         }),
      },

      totalVisible: {
         type: Number,
         default: 7,
      },
   },
   computed: {
      currentPage: {
         get() {
            return this.meta.current_page;
         },
         set(val) {
            this.$emit('page-changed', val);
         },
      },
      pageRange() {
         let startPage = this.currentPage - this.totalVisible;
         if (startPage < 1) startPage = 1;

         let endPage = startPage;
         endPage = endPage + this.totalVisible;
         if (endPage >= this.meta.last_page) endPage = this.meta.last_page;

         return range(startPage, endPage + 1) || [];
      },
   },
   data() {
      return {
         disableNextLink: false,
         disablePreviousLink: false,
      };
   },
   mounted() {
      this.disablePreviousLink = this.currentPage === 1;
      this.disableNextLink = this.currentPage === this.meta.last_page;
   },
   watch: {
      currentPage(newVal) {
         this.disablePreviousLink = newVal === 1;
         this.disableNextLink = newVal === this.meta.last_page;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-pagination {
   display: grid;
   align-items: center;
   justify-content: space-between;
   grid-template-columns: 1fr auto;

   .pagination {
      margin: 0;

      .page-item {
         &.active {
            .page-link {
               pointer-events: none;
            }
         }

         .page-link {
            cursor: pointer;
         }
      }
   }
}
</style>
